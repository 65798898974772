import styled from 'styled-components'
import React from 'react'
const Card = styled.div`
  background-color: #fcdd8f;
  box-shadow: 10px 10px 8px #888888;
  border-style: solid;
  border-width: 10px;
  border-color: white;
  height: 300px;
  margin: 20px;
`
const CardTitle = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 70px;
  text-align: center;
`

const Banner = props => {
  const url = `url(${props.imgUrl})`
  return (
    <Card
      style={{
        backgroundImage: url,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        margins: '50px',
      }}
    >
      <CardTitle style={{ fontFamily: 'pacifico' }}>{props.title}</CardTitle>
    </Card>
  )
}

export default Banner
