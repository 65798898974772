import React from 'react'
import Banner from '../components/Banner'
import { graphql, Link } from 'gatsby'

import styled from 'styled-components'
import Image from '../components/image'
const Blogs = ({
  data: {
    allMdx: { edges: data },
  },
}) => {
  const posts = data.map(p => p.node)
  return (
    <BlogLayout>
      <div style={{ textAlign: 'center' }}>
        <Image imgUrl="https://i.ibb.co/pjHkLNd/Recipe2.png" />
      </div>
      {posts
        .filter(post => {
          return post.frontmatter.specification !== 'recipes'
        })
        .map((post, key) => (
          <div key={key} style={{ width: 'fit-content' }}>
            <Link style={{ boxShadow: `none` }} to={post.fields.slug}>
              <h1 style={{ color: '#DEA81F', fontFamily: 'pacifico' }}>
                {post.frontmatter.title}
              </h1>
              <Image
                imgUrl={post.frontmatter.img}
                height="300px"
                width="300px"
              ></Image>
              Tags:{post.frontmatter.specification}
            </Link>
          </div>
        ))}
    </BlogLayout>
  )
}

export const BlogLayout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 30px;
`
export const MainQuery = graphql`
  query MainQuery {
    allMdx {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            specification
            img
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`
export default Blogs
